<template>
  <div class="invoice-container">
    <!-- 公司名称 -->
    <div class="company-name">眸芯智能科技</div>

    <!-- 发票类型及数量 -->
    <div class="invoice-types" style="text-align: left;">
      <el-row :gutter="10">
        <el-col :span="3" class="invoice-col">
          <div>增值税专票: <span>{{ specificInvoices }}</span></div>
        </el-col>
        <el-col :span="2" class="invoice-col">
          <div>增值税普票: <span>{{ commonInvoices }}</span></div>
        </el-col>
        <el-col :span="3" class="invoice-col">
          <div>定额发票: <span>{{ quotaInvoices }}</span></div>
        </el-col>
        <el-col :span="2" class="invoice-col">
          <div>火车票: <span>{{ trainTickets }}</span></div>
        </el-col>
        <el-col :span="3" class="invoice-col">
          <div>出租车票: <span>{{ taxiInvoices }}</span></div>
        </el-col>
        <el-col :span="2" class="invoice-col">
          <div>网约车票: <span>{{ hailingInvoices }}</span></div>
        </el-col>
        <el-col :span="3" class="invoice-col">
          <div>航空发票: <span>{{ aeroplaneInvoices }}</span></div>
        </el-col>
        <el-col :span="2" class="invoice-col">
          <div>其他票: <span>{{ otherInvoices }}</span></div>
        </el-col>
        <el-col :span="3" class="invoice-col">
          <div>今日识别: <span>{{ dailyRecognition }}</span></div>
        </el-col>
      </el-row>
    </div>

    <div class="invoice-types" style="text-align: left; margin-top: 20px;">
      <el-form :model="searchForm" @submit.native.prevent="fetchProducts" inline>
        <!-- 发票类型下拉框查询条件 -->
        <el-form-item label="发票类型" label-width="100px">
          <el-select v-model="searchForm.invoice_type" placeholder="请选择">
            <el-option label="---请选择---" value=""></el-option>
            <el-option label="增值税专用发票" value="specific"></el-option>
            <el-option label="增值税普通发票" value="comm"></el-option>
            <el-option label="定额发票" value="oqi"></el-option>
            <el-option label="火车票" value="train"></el-option>
            <el-option label="出租车" value="taxi"></el-option>
            <el-option label="网约车发票" value="hailing"></el-option>
            <el-option label="航空发票" value="aeroplane"></el-option>
            <el-option label="其他" value="other"></el-option>
            <!-- 其他类型选项 -->
          </el-select>
        </el-form-item>

        <!-- 发票名称查询条件 -->
        <el-form-item label="发票名称">
          <el-input v-model="searchForm.invoice_name"></el-input>
        </el-form-item>

        <!-- 发票号码查询条件 -->
        <el-form-item label="发票号码">
          <el-input v-model="searchForm.invoice_number"></el-input>
        </el-form-item>

        <!-- 开始开票时间查询条件 -->
        <el-form-item label="开始开票时间">
          <el-date-picker
              v-model="searchForm.start_invoice_date"
              type="date"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>

        <!-- 截止开票时间查询条件 -->
        <el-form-item label="截止开票时间">
          <el-date-picker
              v-model="searchForm.end_invoice_date"
              type="date"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>

        <!-- 查询按钮 -->
        <el-form-item>
          <el-button type="primary" @click="fetchProducts">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table :data="products.data" style="width: 100%">
      <el-table-column prop="id" label="ID" width="50"></el-table-column>
      <el-table-column prop="invoice_name" label="发票名称"></el-table-column>
      <el-table-column prop="invoice_number" label="发票号码"></el-table-column>
      <el-table-column prop="amount" label="金额"></el-table-column>
      <el-table-column prop="invoice_type" label="发票类型" width="120"></el-table-column>
      <el-table-column prop="invoice_date" label="开票日期"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <a :href="scope.row.image_url" target="_blank">
            <img :src="scope.row.image_url" alt="发票图片" style="max-width: 100px;">
          </a>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页组件容器 -->
    <div class="pagination-container">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="products.current_page"
          :page-size="searchForm.page_size"
          :page-sizes="[5, 10, 20, 50]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="products.total_count">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import axios from 'axios'; // 确保已经安装 axios
import router from '../router'; // 确保引入了 Vue Router 的实例
export default {
  data() {
    return {
      searchForm: {
        invoice_type: '', // 发票类型
        invoice_number: '', // 发票号码
        invoice_name: '', // 发票名称
        start_time: '', // 发票开始时间
        end_time: '', // 发票截止时间
        current_page: 1,
        page_size: 10
      },
      products: {
        data: [], // 用于存放商品数据
        current_page: 1,
        total_count: 0
      },
      currentPage: 1,
      pageSize: 10,
      total: 0,
      token: '',
      stats: {
        train: 0,  // 火车票数量
        taxi: 0,   // 出租车票数量
        specific: 0, // 增值税专用发票数量
        today: 0,  // 当日识别数量
      },
      specificInvoices: 0,
      commonInvoices: 0,
      quotaInvoices: 0,
      trainTickets: 0,
      taxiInvoices: 0,
      hailingInvoices: 0,
      aeroplaneInvoices: 0,
      otherInvoices: 0,
      dailyRecognition: 0,
    };
  },
  // watch: {
  //   // 使用深度 watcher 来监听 searchForm 对象中任意属性的变化
  //   searchForm: {
  //     handler() {
  //       this.fetchProducts();
  //     },
  //     deep: true,
  //   },
  // },
  methods: {
    fetchProducts() {
      // 构建请求参数
      let params = {
        ...this.searchForm,
      };
      // 确保时间字段非空时发送请求
      if (params.start_time && params.end_time) {
        // 格式化日期参数
        console.log('this.searchForm.start_time', this.searchForm.start_invoice_date)
        params.start_invoice_date = this.formatDateToYearMonthDay(this.searchForm.start_invoice_date)
        params.end_invoice_date = this.formatDateToYearMonthDay(this.searchForm.end_invoice_date)
      }
      // 发送请求前可以清除当前数据，防止展示旧数据
      this.products.data = [];
      axios.get('https://ocr.maxwebx.cn/query_invoice_list', {
        params: this.searchForm,
        headers: {
          Authorization: `Bearer ${this.token}`
        },
      }).then(response => {
        if (response.data && response.data.code === 200) {
          this.products.data = response.data.data; // 赋值商品数据
          this.products.current_page = response.data.current_page; // 当前页码
          this.products.total_count = response.data.total_count; // 总条目数
          this.searchForm.page_size = response.data.page_size; // 每页显示条数
        } else {
          // 根据实际业务处理错误情况
          console.error('Failed to fetch products:', response.data);
        }
      }).catch(error => {
        // 网络错误处理
        console.error('Error fetching products:', error);
      });
    },
    formatDate(date) {
      // 格式化日期为 yyyy-MM-dd 格式
      const d = new Date(date);
      return d.toISOString().split('T')[0];
    },
    formatDateToYearMonthDay(dateString) {
      if (!dateString) return '';
      // 假设日期字符串格式为 "yyyy-MM-ddTHH:mm:ss.S"
      console.log('dateString', dateString)
      return dateString.substring(0, 10).replace(/-/g, '-');
    },
    handleSizeChange(newSize) {
      this.searchForm.page_size = newSize;
      this.fetchProducts();
    },
    handleCurrentChange(newPage) {
      this.searchForm.current_page = newPage;
      this.fetchProducts();
    },
    setAxiosInterceptors() {
      // 设置请求拦截器
      axios.interceptors.request.use(config => {
        config.headers.Authorization = `Bearer ${this.token}`;
        return config;
      }, error => {
        return Promise.reject(error);
      });
    },
    // 添加一个新的方法来获取统计数据
    fetchStatistics() {
      axios.get('https://ocr.maxwebx.cn/query_statistical_data', {
        headers: {
          Authorization: `Bearer ${this.token}`
        },
      })
          .then(response => {
            if (response.data && response.data.code === 200) {
              const statsData = response.data.data;
              this.specificInvoices = statsData.specific; // 增值税专票
              this.commonInvoices = statsData.comm; // 增值税普票
              this.quotaInvoices = statsData.oqi; // 定额发票
              this.trainTickets = statsData.train; // 火车票
              this.taxiInvoices = statsData.taxi; // 出租车票
              // 网约车票在原始数据中没有对应的字段，可能需要添加或使用其他字段
              this.aeroplaneInvoices = statsData.aeroplane; // 航空发票
              this.otherInvoices = statsData.other; // 其他票
              // 假设 dailyRecognition 是从其他接口获取的今日识别数量
              this.dailyRecognition = this.stats.today;
            } else {
              console.error('Failed to fetch statistics:', response.data);
            }
          })
          .catch(error => {
            console.error('Error fetching statistics:', error);
          });
    },
    // 新增方法来获取今日识别的数量
    fetchDailyRecognition() {
      axios.get('https://ocr.maxwebx.cn/query_current_records', {
        headers: {
          Authorization: `Bearer ${this.token}`
        },
      })
          .then(response => {
            if (response.data && response.data.code === 200) {
              this.dailyRecognition = response.data.data.num; // 更新今日识别数量
            } else {
              console.error('Failed to fetch daily recognition count:', response.data);
            }
          })
          .catch(error => {
            console.error('Error fetching daily recognition count:', error);
          });
    },
  },
  created() {
    this.token = localStorage.getItem('token');
    if (!this.token) {
      // 如果token为空，则跳转到登录页面
      router.push('/login'); // 假设登录页面的路由名称为 'index'
      return; // 避免执行后续的逻辑
    }
    this.setAxiosInterceptors();
    this.fetchProducts(); // 获取发票列表
    this.fetchStatistics(); // 获取统计数据
    this.fetchDailyRecognition(); // 组件创建时获取今日识别数量
  },
};
</script>

<style scoped>
.invoice-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 108px;
  box-sizing: border-box;
}

/* 分页组件容器 */
.pagination-container {
  width: 100%;
  text-align: center; /* 使分页组件内的元素水平居中排列 */
  margin-top: 10px; /* 根据需要添加上边距 */
}

.el-table .cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px; /* 确保省略号显示在单元格内 */
}

.company-name {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
}

.el-form--inline .el-form-item {
  margin-right: 10px;
}

.invoice-types {
  width: 100%; /* 确保容器宽度充满其父容器 */
  text-align: left; /* 确保内容靠左对齐 */
}

.invoice-col {
  padding: 5px; /* 根据需要设置内边距 */
  text-align: left; /* 确保每个列内的内容靠左对齐 */
}

</style>
