import Vue from 'vue'
import Router from 'vue-router'
import Login from '../components/Index.vue'
import Products from '../components/InvoiceList.vue'
// ... 其他页面组件的导入 ...

Vue.use(Router)

export default new Router({
    mode: 'history', // 使用history模式，这样URL就不会包含#号
    routes: [
        {
            path: '/login',
            name: 'Index',
            component: Login
        },
        {
            path: '/invoices',
            name: 'InvoiceList',
            component: Products
        },
        {
            path: '/',
            redirect: '/login' // 重定向到登录页面
        },
        // ... 其他路由规则 ...
    ]
})