<template>
  <div class="login-container">
    <h2>登录</h2>
    <form @submit.prevent="handleSubmit">
      <div class="form-group">
        <label for="username" class="label-inline">账号:</label>
        <input type="text" id="username" v-model="username" @focus="isUsernameFocused = true" @blur="isUsernameFocused = false" required>
        <span v-if="!username.trim() && isUsernameFocused" class="error-message">账号不能为空</span>
      </div>
      <div class="form-group">
        <label for="password" class="label-inline">密码:</label>
        <input type="password" id="password" v-model="password" @focus="isPasswordFocused = true" @blur="isPasswordFocused = false" required>
        <span v-if="!password.trim() && isPasswordFocused" class="error-message">密码不能为空</span>
      </div>
      <button type="submit" :disabled="!isFormValid">Login</button>
    </form>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'login',
  data() {
    return {
      username: '',
      password: '',
      loginError: null, // 用于存储登录错误信息
      isUsernameFocused: false,
      isPasswordFocused: false,
    };
  },
  computed: {
    isFormValid() {
      return this.username.trim() !== '' && this.password.trim() !== '';
    }
  },
  methods: {
    async handleSubmit() {
      this.loginError = null; // 清除之前的错误信息
      try {
        const response = await fetch('https://ocr.maxwebx.cn/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ account: this.username, password: this.password }),
        });

        if (!response.ok) {
          // 如果响应状态码不是2xx，抛出异常
          console.log(`Login failed with status: ${response.status}`);
        }

        const data = await response.json();
        if (data.code === 200 && data.data && data.data.token) {
          // 登录成功，存储token
          localStorage.setItem('token', data.data.token); // 假设使用localStorage存储token

          // 登录成功后跳转到商品列表页面
          await this.$router.push('/invoices');
        } else {
          // 处理登录失败的情况
          this.loginError = data.message || 'Login failed due to an unknown reason.';
        }
      } catch (error) {
        // 捕获异常并设置错误信息
        this.loginError = error.message || 'An unknown error occurred during login.';
        console.error('Error during login:', error);
      }
    }
  }
}
</script>

<style scoped>
html, body {
  height: 100%;
  margin: 0;
}

.login-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.label-inline {
  margin-right: 10px; /* 标签和输入框之间的间距 */
  white-space: nowrap; /* 防止标签换行 */
  font-size: 16px; /* 标签字体大小 */
}

input[type="text"],
input[type="password"] {
  flex-grow: 1; /* 让输入框占据剩余空间 */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  margin-right: 5px; /* 避免输入框与错误消息重叠 */
}

button {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
}

button:disabled {
  background-color: #ccc;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-left: 10px; /* 错误消息与输入框的间距 */
}

/* 媒体查询：适配移动端 */
@media (max-width: 767px) {
  .login-container {
    margin: 20px auto;
    padding: 15px;
  }

  .label-inline {
    font-size: 14px; /* 移动端的标签字体大小 */
  }

  input[type="text"],
  input[type="password"] {
    font-size: 16px; /* 移动端输入框字体大小 */
  }

  button {
    font-size: 14px; /* 移动端按钮字体大小 */
  }

  .error-message {
    font-size: 14px; /* 移动端错误消息字体大小 */
  }
}
</style>