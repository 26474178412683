<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import Index from "@/components/Index.vue";

export default {
  name: 'App',
  components: {
    // eslint-disable-next-line no-mixed-spaces-and-tabs,vue/no-unused-components
	  Index
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
